// configs
import limo from './limo';
import eventus from './eventus';
import accurate from './accurate';
import defaultConfig from './default';

// interfaces
import type { Config } from '../interfaces';

const configMapProd: Record<string, Config> = {
  org_2YGUUKtZwveOHVPBy2nu0z5XeKd: eventus,
  org_2ZEW3fqMubnZI2SfhNcEF6W6pvS: accurate,
  // post
  org_2celPO1IPT1IU15ocNIEfOVzXu4: {
    host: 'https://audit.limohealth.com/ready-for-review',
    modules: [],
    slug: 'post',
  } as any,
  // healthsnap
  org_2eWOApv2viy3TqIjj00PIZivuoM: {
    host: 'https://sandbox.limohealth.com/completed-audits',
    modules: [],
    slug: 'healthsnap',
  } as any,
  // thirdeye
  org_2h4dYLE7WNKwZVUSOyxIKzIZzQ8: {
    host: 'https://audit.limohealth.com/overview',
    modules: [],
    slug: 'thirdeye',
  } as any,
  // preventive measures
  org_2i4tbxjxIW0pM7OkMOFUjB3g3IO: {
    host: 'https://audit.limohealth.com/sandbox',
    modules: [],
    slug: 'preventive-measures',
  } as any,
  org_2axNFxZAi6UPIdvBd757Z0zDUbZ: {
    host: 'https://demo.limohealth.com/ready-for-review',
    modules: [],
    slug: 'demo',
  } as any,
  // Kidscare Home Health
  org_2jU7C3zF7GM1WRRCK4d2bhRT8Rk: {
    host: 'https://sandbox.limohealth.com/completed-audits',
    modules: [],
    slug: 'kidscare-home-health',
  } as any,
};

const configMapDev: Record<string, Config> = {
  org_2ZRvs043bWuW7sH1RPJDRsg9zxq: eventus,
  org_2YE7pSfmrZpWPVNMGxJBU3H1NXJ: limo,
  org_2ZF1lMbj7QpwoGA5Zv3KRCAWp1F: accurate,
};

export const getConfigByOrgId = (orgId: string = '') => {
  const isOnDEVConfig = configMapDev[orgId];
  const isOnPRODConfig = configMapProd[orgId];
  const config = isOnDEVConfig || isOnPRODConfig || defaultConfig;

  return config;
};
