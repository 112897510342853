// constants
import { Module } from '../constants';

export default {
  slug: 'eventus',
  modules: [
    Module.PendingAudits,
    Module.AuditResults,
    Module.Notes,
    Module.Providers,
    Module.AuditTools,
    Module.PreBillReviews,
  ],
  betaModules: [Module.PreBillReviews],
  supportAI: true,
  host: 'https://eventus.limohealth.com/pending-audits',
};
