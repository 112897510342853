export enum Module {
  PreBillReviews = 'Pre-Bill Review',
  PendingAudits = 'Pending Audits',
  AuditResults = 'Audit Results',
  Notes = 'Notes',
  Providers = 'Providers',
  AuditTools = 'Audit Tools',
  Procedures = 'Procedures',
  Documents = 'Documents',
}

export const modulesPaths: Record<Module, string> = {
  [Module.PreBillReviews]: '/pre-bill-reviews',
  [Module.PendingAudits]: '/pending-audits',
  [Module.AuditResults]: '/results',
  [Module.Notes]: '/notes',
  [Module.Providers]: '/providers',
  [Module.AuditTools]: '/audit-tools',
  [Module.Procedures]: '/procedures',
  [Module.Documents]: '/documents',
};
