import {
  OrganizationSwitcher,
  UserButton,
  useUser,
  useOrganization,
} from '@clerk/nextjs';
import Link from 'next/link';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { Box, Text, Icon, Flex, useTheme, Badge } from '@chakra-ui/react';

// components
import ModulesSkeleton from './ModulesSkeleton';

// hooks
import {
  useClientConfig,
  modulesIconMap,
  modulesPathMap,
} from '@/configuration/client';

interface NavItemProps {
  href: string;
  name: string;
  icon: JSX.Element;
  active: boolean;
  isBeta: boolean;
}

const NavItem = ({ href, name, icon, active, isBeta }: NavItemProps) => (
  <Link href={href} passHref>
    <Flex
      mb={1}
      width={190}
      alignItems={'center'}
      fontWeight={active ? '500' : '400'}
      backgroundColor={active ? 'cyan.100' : 'transparent'}
      _hover={{
        backgroundColor: active ? 'cyan.100' : 'gray.200',
      }}
      position="relative"
      px={'18px'}
      py={'12px'}
      borderRadius={8}
    >
      {icon}
      <Text fontSize={'15px'} ml={'12px'}>
        {name}
      </Text>
      {isBeta && (
        <Flex
          width="100%"
          position="absolute"
          right={0}
          top={0}
          left={2}
          justifyContent="end"
        >
          <Badge
            fontSize={9}
            variant="solid"
            colorScheme={active ? 'cyan' : 'gray'}
          >
            BETA
          </Badge>
        </Flex>
      )}
    </Flex>
  </Link>
);

const LeftNav = () => {
  const theme = useTheme();
  const pathname = usePathname();
  const clientConfig = useClientConfig();
  const { isLoaded, user } = useUser();
  const { isLoaded: isOrgLoaded, organization } = useOrganization();
  const isLimoMember =
    user?.primaryEmailAddress?.emailAddress.endsWith('@limo.tech');
  const defaultCardStyles = {
    boxShadow: 'none',
    borderStyle: 'solid',
    borderColor: theme.colors.gray[200],
    borderWidth: '1px',
    borderRadius: '8px',
  };

  const modules = clientConfig.modules.map((module) => ({
    name: module,
    icon: modulesIconMap[module],
    path: modulesPathMap[module],
  }));

  return (
    <Flex
      minW={220}
      maxW={220}
      direction={'column'}
      alignItems={'center'}
      borderRight={'1px solid'}
      borderRightColor={'#dadfe2'}
      h={'100%'}
    >
      <Box position="relative" height={'36px'} width={'120px'} my={12}>
        <Link href="/" passHref>
          <Image
            priority
            src="/logo/LIMO_FULL_transp_bg.png"
            alt="LIMO Logo"
            fill
            style={{
              objectFit: 'contain',
            }}
          />
        </Link>
      </Box>
      <Box>
        {isOrgLoaded ? (
          <>
            {modules.map((module) => {
              return (
                <NavItem
                  key={module.path}
                  href={module.path}
                  active={pathname.startsWith(module.path)}
                  name={module.name}
                  icon={<Icon as={module.icon} />}
                  isBeta={clientConfig.betaModules.includes(module.name)}
                />
              );
            })}
          </>
        ) : (
          <ModulesSkeleton />
        )}
      </Box>
      <Flex flexGrow={1} alignItems={'flex-end'} width={'100%'} height="64px">
        <Flex
          borderTop={'1px solid #dadfe2'}
          width={'100%'}
          display="flex"
          paddingY="8px"
          height="64px"
          paddingX="8px"
          justifyContent="center"
        >
          <Flex
            backgroundColor="gray.200"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderRadius={8}
          >
            {isLoaded ? (
              <UserButton
                appearance={{
                  elements: {
                    rootBox: {
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                      padding: '12px 18px 12px 18px',
                      '&:hover': {
                        backgroundColor: theme.colors.cyan[100],
                      },
                    },
                    userButtonAvatarBox: {
                      width: '24px',
                      height: '24px',
                    },
                    userButtonPopoverCard: defaultCardStyles,
                  },
                }}
              />
            ) : (
              <Box
                minHeight="36px"
                height="34px"
                backdropBlur="2xl"
                backgroundColor="gray.100"
                width="100%"
                borderRight={`1px solid ${theme.colors.gray[300]}`}
                minWidth="48px"
              />
            )}
            {isOrgLoaded ? (
              <>
                {organization === null ? null : (
                  <OrganizationSwitcher
                    afterSelectOrganizationUrl="/redirect"
                    hidePersonal
                    appearance={{
                      elements: {
                        rootBox: {
                          height: '100%',
                          display: 'flex',
                          borderLeftStyle: 'solid',
                          borderLeftWidth: '1px',
                          borderLeftColor: theme.colors.gray[300],
                          borderTopRightRadius: '8px',
                          borderBottomRightRadius: '8px',
                          '&:hover': {
                            backgroundColor: theme.colors.cyan[100],
                          },
                        },
                        organizationSwitcherTrigger: {
                          '&:focus': {
                            boxShadow: 'none',
                          },
                        },
                        organizationPreviewAvatarBox: {
                          width: '24px',
                          height: '24px',
                          marginLeft: '8px',
                        },
                        organizationSwitcherPopoverActions: {
                          display: isLimoMember ? '' : 'none',
                        },
                        organizationSwitcherTriggerIcon: {
                          width: '12px',
                          height: '12px',
                        },
                        organizationPreviewMainIdentifier: {
                          fontWeight: '400',
                          '&:hover': {
                            fontWeight: '500',
                          },
                        },
                        organizationSwitcherPopoverCard: defaultCardStyles,
                      },
                    }}
                  />
                )}
              </>
            ) : (
              <Box
                minHeight="36px"
                height="34px"
                backdropBlur="2xl"
                backgroundColor="gray.100"
                width="100%"
                minWidth="124px"
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LeftNav;
