/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Analysis } from '../models/Analysis';
import type { AnalysisModule } from '../models/AnalysisModule';
import type { ApiKey } from '../models/ApiKey';
import type { Audit } from '../models/Audit';
import type { AuditPipeline } from '../models/AuditPipeline';
import type { AuditPipelinePrisma } from '../models/AuditPipelinePrisma';
import type { AuditPrisma } from '../models/AuditPrisma';
import type { AuditState } from '../models/AuditState';
import type { AuditThirdEye } from '../models/AuditThirdEye';
import type { CreateAnalysisModuleRequest } from '../models/CreateAnalysisModuleRequest';
import type { CreateAnalysisRequest } from '../models/CreateAnalysisRequest';
import type { CreateAuditPipelineRequest } from '../models/CreateAuditPipelineRequest';
import type { CreateEncounterRequest } from '../models/CreateEncounterRequest';
import type { CreateEncounterRequestThirdEye } from '../models/CreateEncounterRequestThirdEye';
import type { CreateProviderRequest } from '../models/CreateProviderRequest';
import type { CreateReportRequest } from '../models/CreateReportRequest';
import type { DownloadResponse } from '../models/DownloadResponse';
import type { Encounter } from '../models/Encounter';
import type { EncounterPrisma } from '../models/EncounterPrisma';
import type { GetPreBillNoteResponse } from '../models/GetPreBillNoteResponse';
import type { ListPreBillReviewResponse } from '../models/ListPreBillReviewResponse';
import type { PreventiveMeasuresAuditSandbox } from '../models/PreventiveMeasuresAuditSandbox';
import type { PreventiveMeasuresAuditSandboxRAW } from '../models/PreventiveMeasuresAuditSandboxRAW';
import type { Provider } from '../models/Provider';
import type { ProviderPrisma } from '../models/ProviderPrisma';
import type { Report } from '../models/Report';
import type { ReportDownload } from '../models/ReportDownload';
import type { ReportPrisma } from '../models/ReportPrisma';
import type { Sandbox } from '../models/Sandbox';
import type { UpdateAnalysisModuleRequest } from '../models/UpdateAnalysisModuleRequest';
import type { UpdateAnalysisRequest } from '../models/UpdateAnalysisRequest';
import type { UpdateAuditPipelineRequest } from '../models/UpdateAuditPipelineRequest';
import type { UpdateAuditResultRequest } from '../models/UpdateAuditResultRequest';
import type { UploadResponse } from '../models/UploadResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
    /**
     * @returns any Ok
     * @throws ApiError
     */
    public static listAuditsForSandbox(): CancelablePromise<(Array<Sandbox> | Array<PreventiveMeasuresAuditSandbox>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sandbox',
        });
    }
    /**
     * @returns any Ok
     * @throws ApiError
     */
    public static listAuditResultsForSandbox(): CancelablePromise<Array<any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sandbox/results',
        });
    }
    /**
     * @param auditId
     * @returns any Ok
     * @throws ApiError
     */
    public static listAuditForSandbox(
        auditId: string,
    ): CancelablePromise<(Array<Sandbox> | {
        similarNotes: Array<PreventiveMeasuresAuditSandbox>;
        patient: string;
        results: Array<any>;
        audit: PreventiveMeasuresAuditSandboxRAW;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sandbox/{auditId}',
            path: {
                'auditId': auditId,
            },
        });
    }
    /**
     * @returns any Ok
     * @throws ApiError
     */
    public static listReports(): CancelablePromise<(Array<Report> | Array<ReportPrisma>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reports',
        });
    }
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static createReports(
        requestBody: CreateReportRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/reports',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param reportId
     * @returns ReportDownload Ok
     * @throws ApiError
     */
    public static downloadReport(
        reportId: string,
    ): CancelablePromise<ReportDownload> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reports/{reportId}',
            path: {
                'reportId': reportId,
            },
        });
    }
    /**
     * @param reportId
     * @returns void
     * @throws ApiError
     */
    public static deleteReport(
        reportId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/reports/{reportId}',
            path: {
                'reportId': reportId,
            },
        });
    }
    /**
     * @returns any Ok
     * @throws ApiError
     */
    public static listProviders(): CancelablePromise<(Array<Provider> | Array<ProviderPrisma>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/providers',
        });
    }
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static createProviders(
        requestBody: Array<CreateProviderRequest>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/providers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param providerId
     * @returns any Ok
     * @throws ApiError
     */
    public static getProvider(
        providerId: string,
    ): CancelablePromise<(Provider | ProviderPrisma)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/providers/{providerId}',
            path: {
                'providerId': providerId,
            },
        });
    }
    /**
     * @param providerId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateProvider(
        providerId: string,
        requestBody: CreateProviderRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/providers/{providerId}',
            path: {
                'providerId': providerId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param providerId
     * @returns void
     * @throws ApiError
     */
    public static deleteProvider(
        providerId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/providers/{providerId}',
            path: {
                'providerId': providerId,
            },
        });
    }
    /**
     * @param state
     * @returns ListPreBillReviewResponse Ok
     * @throws ApiError
     */
    public static listPreBillReviews(
        state?: 'ACTIVE' | 'MISSED' | 'CAPTURED' | 'DISMISSED',
    ): CancelablePromise<ListPreBillReviewResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/prebill',
            query: {
                'state': state,
            },
        });
    }
    /**
     * @param noteId
     * @returns GetPreBillNoteResponse Ok
     * @throws ApiError
     */
    public static listPreBillReview(
        noteId: string,
    ): CancelablePromise<GetPreBillNoteResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/prebill/{noteId}',
            path: {
                'noteId': noteId,
            },
        });
    }
    /**
     * @param opportunityId
     * @returns void
     * @throws ApiError
     */
    public static approvePreBillNote(
        opportunityId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/prebill/{opportunityId}/approve',
            path: {
                'opportunityId': opportunityId,
            },
        });
    }
    /**
     * @returns ApiKey Ok
     * @throws ApiError
     */
    public static createApiKey(): CancelablePromise<ApiKey> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organization/api-key',
        });
    }
    /**
     * @returns any Ok
     * @throws ApiError
     */
    public static listEncounter(): CancelablePromise<(Array<Encounter> | Array<EncounterPrisma>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/encounters',
        });
    }
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static createEncountersThirdEye(
        requestBody: Array<CreateEncounterRequestThirdEye>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/encounters',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param encounterId
     * @returns any Ok
     * @throws ApiError
     */
    public static getEncounter(
        encounterId: string,
    ): CancelablePromise<(Encounter | EncounterPrisma)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/encounters/{encounterId}',
            path: {
                'encounterId': encounterId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static createEncounters(
        requestBody: Array<CreateEncounterRequest>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/encounters/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param encounterId
     * @returns AuditThirdEye Ok
     * @throws ApiError
     */
    public static getEncounterAudits(
        encounterId: string,
    ): CancelablePromise<Array<AuditThirdEye>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/encounters/{encounterId}/audits',
            path: {
                'encounterId': encounterId,
            },
        });
    }
    /**
     * @param providerId
     * @returns any Ok
     * @throws ApiError
     */
    public static getProviderEncounters(
        providerId: string,
    ): CancelablePromise<(Array<Encounter> | Array<EncounterPrisma>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/encounters/provider/{providerId}',
            path: {
                'providerId': providerId,
            },
        });
    }
    /**
     * @param patientId
     * @returns any Ok
     * @throws ApiError
     */
    public static getPatientEncounters(
        patientId: string,
    ): CancelablePromise<(Array<Encounter> | Array<EncounterPrisma>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/encounters/patient/{patientId}',
            path: {
                'patientId': patientId,
            },
        });
    }
    /**
     * @returns UploadResponse Ok
     * @throws ApiError
     */
    public static getChartUploadLink(): CancelablePromise<UploadResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/charts/upload',
        });
    }
    /**
     * @param file
     * @returns void
     * @throws ApiError
     */
    public static uploadCharts(
        file: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/charts/upload/{file}',
            path: {
                'file': file,
            },
        });
    }
    /**
     * @param chartId
     * @returns DownloadResponse Ok
     * @throws ApiError
     */
    public static getChartDownloadLink(
        chartId: string,
    ): CancelablePromise<DownloadResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/charts/chart/{chartId}',
            path: {
                'chartId': chartId,
            },
        });
    }
    /**
     * @returns AuditPipeline Ok
     * @throws ApiError
     */
    public static listAuditPipelines(): CancelablePromise<Array<AuditPipeline>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audit-pipelines',
        });
    }
    /**
     * @param requestBody
     * @returns AuditPipeline Ok
     * @throws ApiError
     */
    public static createAuditPipeline(
        requestBody: CreateAuditPipelineRequest,
    ): CancelablePromise<AuditPipeline> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/audit-pipelines',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param pipelineId
     * @returns any Ok
     * @throws ApiError
     */
    public static getAuditPipeline(
        pipelineId: string,
    ): CancelablePromise<(AuditPipeline | AuditPipelinePrisma)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audit-pipelines/{pipelineId}',
            path: {
                'pipelineId': pipelineId,
            },
        });
    }
    /**
     * @param pipelineId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateAuditPipeline(
        pipelineId: string,
        requestBody: UpdateAuditPipelineRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/audit-pipelines/{pipelineId}',
            path: {
                'pipelineId': pipelineId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param pipelineId
     * @returns void
     * @throws ApiError
     */
    public static deleteAuditPipeline(
        pipelineId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/audit-pipelines/{pipelineId}',
            path: {
                'pipelineId': pipelineId,
            },
        });
    }
    /**
     * @param state
     * @returns any Ok
     * @throws ApiError
     */
    public static listAudits(
        state: AuditState,
    ): CancelablePromise<(Array<Audit> | Array<AuditPrisma>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audits',
            query: {
                'state': state,
            },
        });
    }
    /**
     * @param state
     * @returns number Ok
     * @throws ApiError
     */
    public static countAudits(
        state: AuditState,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audits/count',
            query: {
                'state': state,
            },
        });
    }
    /**
     * @param days
     * @returns any Ok
     * @throws ApiError
     */
    public static listAuditsForSummary(
        days: number,
    ): CancelablePromise<(Array<Audit> | Array<AuditPrisma>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audits/summary',
            query: {
                'days': days,
            },
        });
    }
    /**
     * @param state
     * @returns number Ok
     * @throws ApiError
     */
    public static countAuditsLast24Hours(
        state?: AuditState,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audits/count/last24hours',
            query: {
                'state': state,
            },
        });
    }
    /**
     * @param auditIds
     * @returns any Ok
     * @throws ApiError
     */
    public static listAuditsResults(
        auditIds: Array<string>,
    ): CancelablePromise<Array<{
        auditId: string;
        results: Array<any>;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audits/results',
            query: {
                'auditIds': auditIds,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static markAllQueued(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/audits/mark-all-queued',
        });
    }
    /**
     * @param auditId
     * @returns void
     * @throws ApiError
     */
    public static resetAudit(
        auditId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/audits/{auditId}/reset',
            path: {
                'auditId': auditId,
            },
        });
    }
    /**
     * @param auditId
     * @returns Audit Ok
     * @throws ApiError
     */
    public static getAudit(
        auditId: string,
    ): CancelablePromise<Audit> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audits/{auditId}',
            path: {
                'auditId': auditId,
            },
        });
    }
    /**
     * @param auditId
     * @param state
     * @returns Audit Ok
     * @throws ApiError
     */
    public static updateAudit(
        auditId: string,
        state: AuditState,
    ): CancelablePromise<Audit> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/audits/{auditId}',
            path: {
                'auditId': auditId,
            },
            query: {
                'state': state,
            },
        });
    }
    /**
     * @param auditId
     * @returns void
     * @throws ApiError
     */
    public static deleteAudit(
        auditId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/audits/{auditId}',
            path: {
                'auditId': auditId,
            },
        });
    }
    /**
     * @param auditId
     * @param resultId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateAuditResult(
        auditId: string,
        resultId: string,
        requestBody: UpdateAuditResultRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/audits/{auditId}/results/{resultId}',
            path: {
                'auditId': auditId,
                'resultId': resultId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns AnalysisModule Ok
     * @throws ApiError
     */
    public static listAnalysisModules(): CancelablePromise<Array<AnalysisModule>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/analysis-modules',
        });
    }
    /**
     * @param requestBody
     * @returns AnalysisModule Ok
     * @throws ApiError
     */
    public static createAnalysisModule(
        requestBody: CreateAnalysisModuleRequest,
    ): CancelablePromise<AnalysisModule> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/analysis-modules',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param moduleId
     * @returns AnalysisModule Ok
     * @throws ApiError
     */
    public static getAnalysisModule(
        moduleId: string,
    ): CancelablePromise<AnalysisModule> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/analysis-modules/{moduleId}',
            path: {
                'moduleId': moduleId,
            },
        });
    }
    /**
     * @param moduleId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateAnalysisModule(
        moduleId: string,
        requestBody: UpdateAnalysisModuleRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/analysis-modules/{moduleId}',
            path: {
                'moduleId': moduleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param moduleId
     * @returns void
     * @throws ApiError
     */
    public static deleteAnalysisModule(
        moduleId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/analysis-modules/{moduleId}',
            path: {
                'moduleId': moduleId,
            },
        });
    }
    /**
     * @param moduleId
     * @param requestBody
     * @returns Analysis Ok
     * @throws ApiError
     */
    public static createAnalysis(
        moduleId: string,
        requestBody: CreateAnalysisRequest,
    ): CancelablePromise<Analysis> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/analysis-modules/{moduleId}/analyses',
            path: {
                'moduleId': moduleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param moduleId
     * @param analysisId
     * @param requestBody
     * @returns Analysis Ok
     * @throws ApiError
     */
    public static updateAnalysis(
        moduleId: string,
        analysisId: string,
        requestBody: UpdateAnalysisRequest,
    ): CancelablePromise<Analysis> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/analysis-modules/{moduleId}/analyses/{analysisId}',
            path: {
                'moduleId': moduleId,
                'analysisId': analysisId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param moduleId
     * @param analysisId
     * @returns void
     * @throws ApiError
     */
    public static deleteAnalysis(
        moduleId: string,
        analysisId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/analysis-modules/{moduleId}/analyses/{analysisId}',
            path: {
                'moduleId': moduleId,
                'analysisId': analysisId,
            },
        });
    }
}
