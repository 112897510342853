import { Skeleton, VStack } from '@chakra-ui/react';

const ModulesSkeleton = () => {
  return (
    <VStack spacing={4}>
      <Skeleton
        height="40px"
        bg="green.500"
        color="white"
        width="100%"
        fadeDuration={1}
      />
      <Skeleton
        height="40px"
        bg="green.500"
        color="white"
        width="100%"
        fadeDuration={1}
      />
      <Skeleton
        height="40px"
        bg="green.500"
        color="white"
        width="100%"
        fadeDuration={1}
      />
      <Skeleton
        height="40px"
        bg="green.500"
        color="white"
        width="100%"
        fadeDuration={1}
      />
      <Skeleton
        height="40px"
        bg="green.500"
        color="white"
        width="100%"
        fadeDuration={1}
      />
    </VStack>
  );
};

export default ModulesSkeleton;
