import { UserButton, OrganizationSwitcher } from '@clerk/nextjs';
import { Flex, Box } from '@chakra-ui/react';

const Header = () => (
  <></>
  // <Box borderBottom={'1px solid'} borderBottomColor={'#dadfe2'}>
  //   <Flex
  //     height={20}
  //     alignItems={'center'}
  //     justifyContent={'flex-end'}
  //     paddingX={8}
  //   >
  //     <UserButton
  //       showName={true}
  //       appearance={{
  //         elements: {
  //           userButtonOuterIdentifier: {
  //             fontWeight: '400',
  //           },
  //           userButtonBox: {
  //             flexDirection: 'row-reverse',
  //           },
  //         },
  //       }}
  //     />
  //   </Flex>
  // </Box>
);

export default Header;
