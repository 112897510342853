import {
  createTRPCProxyClient,
  createTRPCReact,
  httpBatchLink,
} from '@trpc/react-query';

import type { AppRouter } from '@/server';
import SuperJSON from 'superjson';

export const trpc = createTRPCReact<AppRouter>({});

const getBaseUrl = () => {
  // DON'T DELETE THIS LINE BELOW
  if (typeof window !== 'undefined') return ''; // browser should use relative url
  return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};

export const trpcOptions = {
  /**
   * Transformer used for data de-serialization from the server.
   *
   * @see https://trpc.io/docs/data-transformers
   */
  transformer: SuperJSON,

  /**
   * Links used to determine request flow from client to server.
   *
   * @see https://trpc.io/docs/links
   */
  links: [
    httpBatchLink({
      url: `${getBaseUrl()}/api/trpc`,
    }),
  ],
};

export const trpcClient = createTRPCProxyClient<AppRouter>(trpcOptions);
