import {
  IoCheckboxOutline,
  IoListSharp,
  IoRocketOutline,
  IoSettingsOutline,
} from 'react-icons/io5';
import { FiUsers } from 'react-icons/fi';
import { IconType } from 'react-icons/lib';
import { AiOutlineAudit } from 'react-icons/ai';
import { useOrganization } from '@clerk/nextjs';
import { MdOutlineHealthAndSafety, MdOutlineFolder } from 'react-icons/md';

// config
import defaultConfig from './clients/default';

// interfaces
import { Config } from './interfaces';

// constants
import { Module, modulesPaths } from './constants';

// utils
import { getConfigByOrgId } from './clients/index';

export const modulesIconMap: Record<Module, IconType> = {
  [Module.PreBillReviews]: IoRocketOutline,
  [Module.PendingAudits]: IoCheckboxOutline,
  [Module.AuditResults]: AiOutlineAudit,
  [Module.Notes]: IoListSharp,
  [Module.Providers]: FiUsers,
  [Module.AuditTools]: IoSettingsOutline,
  [Module.Procedures]: MdOutlineHealthAndSafety,
  [Module.Documents]: MdOutlineFolder,
};

export const modulesPathMap = modulesPaths;

export const useClientConfig = (): Config => {
  const { isLoaded: isOrgLoaded, organization } = useOrganization();

  if (isOrgLoaded === false) {
    return defaultConfig;
  }

  if (organization === null || organization === undefined) {
    return defaultConfig;
  }

  const config = getConfigByOrgId(organization.id);
  return config || defaultConfig;
};
