'use client';
import { usePathname } from 'next/navigation';
import { Flex, Text, Stack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useAuth, useOrganization, useOrganizationList } from '@clerk/nextjs';

// components
import Header from './header';
import LeftNav from './leftnav';

// trpc
import { TrpcProvider } from '@/app/_trpc/Provider';

// interfaces
import { type UserOrganizationInvitationResource } from '@clerk/types';
import { OpenAPI } from '@/generated';
import { SWRConfig } from 'swr';

interface LayoutProps {
  children: React.ReactNode;
}

const getServer = () => {
  if (process.env.NEXT_PUBLIC_API_URL) {
    return process.env.NEXT_PUBLIC_API_URL;
  } else if (
    window?.location?.host === 'dev.audit.limohealth.com' ||
    window?.location?.host === 'dev.limohealth.com'
  ) {
    return 'https://api.dev.limohealth.com';
  } else if ((window?.location?.host ?? '').endsWith('limohealth.com')) {
    return 'https://api.limohealth.com';
  }
  throw Error('No server configured');
};

OpenAPI.BASE = getServer() as string;

const SetActiveOrganizationTrigger = () => {
  const [notFound, setNotFound] = useState(false);
  const [isAcceptingInvitationManually, setIsAcceptingInvitationManually] =
    useState(false);

  const { isLoaded, userMemberships, setActive, userInvitations } =
    useOrganizationList({
      userInvitations: {
        keepPreviousData: true,
      },
      userMemberships: {
        keepPreviousData: true,
      },
    });

  const handleAcceptInvite = useCallback(
    async (invitation: UserOrganizationInvitationResource) => {
      setIsAcceptingInvitationManually(true);

      try {
        await invitation.accept();
        setActive?.({ organization: invitation.publicOrganizationData.id });
      } catch (error) {
        return error;
      }
    },
    [setActive, setIsAcceptingInvitationManually]
  );

  useEffect(() => {
    if (isLoaded && userMemberships) {
      setNotFound(false);

      if (userMemberships.data.length > 0) {
        const org = userMemberships.data[0];
        setActive({ organization: org.organization.id });
      } else if (userInvitations.data?.length > 0) {
        if (isAcceptingInvitationManually === false) {
          const invitation = userInvitations.data[0];
          handleAcceptInvite(invitation);
        }
      } else {
        setNotFound(true);
      }
    }
  }, [
    isLoaded,
    userMemberships,
    userInvitations,
    setActive,
    isAcceptingInvitationManually,
  ]);

  if (notFound) {
    return (
      <Stack
        h={'100%'}
        pb={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={'2xl'} fontWeight={'semibold'} mb={4} align="center">
          Please message your administrator to add you to an organization.
        </Text>
      </Stack>
    );
  }

  return null;
};

const OrganizationProxy = ({ children }: LayoutProps) => {
  const { isLoaded, organization } = useOrganization();

  if (isLoaded) {
    if (organization === null) {
      return <SetActiveOrganizationTrigger />;
    }

    return <>{children}</>;
  }

  return null;
};

const Layout = ({ children }: LayoutProps) => {
  const pathname = usePathname();
  const { getToken } = useAuth();

  const fetcher = async (...args: any[]) => {
    return fetch(`${getServer()}${args[0]}`, {
      headers: { Authorization: `Bearer ${await getToken()}` },
    }).then((res) => res.json());
  };

  useEffect(() => {
    const initAPI = async () => {
      OpenAPI.TOKEN = async () => {
        const token = await getToken();
        return token ?? '';
      };
    };

    initAPI();
  }, []);

  if (
    pathname.startsWith('/sign-in') ||
    pathname.startsWith('/sign-up') ||
    pathname.startsWith('/public')
  ) {
    return <>{children}</>;
  }

  if (pathname.startsWith('/mdm')) {
    return <>Forbidden</>;
  }

  const noPadding =
    pathname.startsWith('/pending-audits/audit/') ||
    pathname.startsWith('/mdm-ai/audit/') ||
    pathname.startsWith('/procedures/') ||
    pathname.startsWith('/pre-bill-reviews/');

  const mainStyle: {
    flexGrow: number;
    overflow: string;
    backgroundColor: string;
    padding?: string;
  } = {
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: '#F7FAFC',
  };

  if (noPadding === false) {
    mainStyle.padding = '36px 72px';
  }

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        fetcher,
      }}
    >
      <TrpcProvider>
        <Flex backgroundColor={'#fdfdfd'} width={'100vw'} height={'100vh'}>
          <LeftNav />
          <Flex direction={'column'} width="100%">
            <Header />
            <main style={mainStyle}>
              <OrganizationProxy>{children}</OrganizationProxy>
            </main>
          </Flex>
        </Flex>
      </TrpcProvider>
    </SWRConfig>
  );
};

export default Layout;
