// constants
import { Module } from '../constants';

export default {
  slug: 'accurate',
  modules: [Module.Procedures, Module.Documents],
  betaModules: [],
  supportAI: true,
  host: 'https://accurate.limohealth.com/procedures',
};
