// constants
import { Module } from '../constants';

export default {
  slug: 'limo',
  modules: [
    Module.PendingAudits,
    Module.AuditResults,
    Module.Notes,
    Module.Providers,
    Module.AuditTools,
    Module.Procedures,
  ],
  supportAI: true,
  betaModules: [],
  host: 'https://limo.limohealth.com/pending-audits',
};
