/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AuditState {
    CREATED = 'CREATED',
    QUEUED = 'QUEUED',
    RUNNING = 'RUNNING',
    UNABLE_TO_RUN = 'UNABLE_TO_RUN',
    READY_FOR_REVIEW = 'READY_FOR_REVIEW',
    COMPLETE = 'COMPLETE',
}
